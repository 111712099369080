import { FC, useEffect, useState } from "react";
import { SocialLinksContainer, SocialLink, SocialIcon } from "./styled";
import { BASE_URL } from "../../../api";
import { SocialLinks } from "../../../types/social";
import { getSocialLinks } from "../../../api/transport/configurationTransport";
import { useConfiguration } from "../../../hooks/useConfiguration";
import { TeamContext } from "../../../types/common";
import zenWhiteIcon from '../../../assets/social/dzen_white.svg';
import zenBlueIcon from '../../../assets/social/dzen_blue.svg';
import { isAcademySiteContext } from "../../../utils";

interface SocialLinksListProps {
    color?: 'blue' | 'white';
    className?: string;
    iconClassName?: string;
    vertical?: boolean;
    teamContext?: TeamContext;
    contactsLink?: boolean;
    myAppLink?: boolean;
    highlightKey?: string;
}

//TODO: вынести все дополнительные иконки в конфиг

export const SocialLinksList: FC<SocialLinksListProps> = ({
    className,
    iconClassName,
    teamContext,
    highlightKey,
    color = 'blue',
    contactsLink = false,
    myAppLink = true,
}) => {
    const [socialLinks, setSocialLinks] = useState<SocialLinks>(null);
    const config = useConfiguration();

    useEffect(() => {
        const teamName = teamContext || config?.teamName;
        getSocialLinks(teamName).then(data => {
            setSocialLinks(data);
        });
    }, [config?.teamName, teamContext]);

    if (!socialLinks) {
        return null;
    }

    let additionalIcons = [];

    if (myAppLink) {
        additionalIcons.push({
            key: 'my',
            name: 'Родные - приложение болельщика',
            url: 'https://my.fcrodina.com',
        });
    }

    if (contactsLink) {
        additionalIcons.push({
            key: 'contacts',
            name: 'Контакты',
            url: 'https://fcrodina.com/club/contacts/',
        });
    }

    return (
        <SocialLinksContainer className={className}>
            {additionalIcons?.map((icon) => {
                if (!icon.url) {
                    return null;
                }
                return (
                    <SocialLink href={icon.url} target="_blank" rel="noreferrer" key={icon.key} title={icon.name} className={highlightKey === icon.key ? 'bg-green-600 rounded-full' : ''}>
                        <SocialIcon src={`${BASE_URL}/static/images/social/${color}/${icon.key}.svg`} alt={icon.name} className={iconClassName} />
                    </SocialLink>
                )
            })}
            {Object.keys(socialLinks).map((key) => {
                const iconName = key === 'x' ? 'twitter' : key;
                if (!socialLinks[key]) {
                    return null;
                }
                return (
                    <SocialLink href={socialLinks[key]} target="_blank" rel="noreferrer" key={key}>
                        <SocialIcon src={`${BASE_URL}/static/images/social/${color}/${iconName}.svg`} alt={key} className={iconClassName} />
                    </SocialLink>
                );
            })}
            {(!isAcademySiteContext(teamContext)) && (
                <SocialLink href="https://dzen.ru/matchday" target="_blank" rel="noreferrer" key="zen" title="Футбол Родины: про матчдэй и не только!">
                    <SocialIcon src={color === 'white' ? zenWhiteIcon : zenBlueIcon} alt="zen" className={iconClassName} />
                </SocialLink>
            )}
        </SocialLinksContainer>
    );
}
