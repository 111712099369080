import { MediaType, TeamContext } from "../types/common";

export const isAcademySiteContext = (context: TeamContext = TeamContext.RODINA) => {
    return (context === TeamContext.ACADEMY || context === TeamContext.RODINA_M);
}

export const getUrlByContext = (
    url: string,
    context: TeamContext = TeamContext.RODINA,
    fullUrl: boolean = false,
) => {
    if (!url) {
        return '';
    }

    if (url.startsWith('http')) {
        return url;
    }

    if (!url.startsWith('/')) {
        url = `/${url}`;
    }

    let prefix = '';

    if (context === TeamContext.RODINA_2) {
        prefix = '/2';
    } else if (context === TeamContext.RODINA_M) {
        prefix = '/youth';
    } else if (context === TeamContext.RODINA_3) {
        prefix = '/m';
    }

    let domain = '';

    if (fullUrl) {
        if (isAcademySiteContext(context)) {
            domain = process.env.NEXT_PUBLIC_ACADEMY_LEGACY_WEBSITE_URL || '';
        } else {
            domain = process.env.NEXT_PUBLIC_RODINA_LEGACY_WEBSITE_URL || '';
        }
    }

    return domain + prefix + url;
};

export const getStaticUrl = (url?: string): string => {
    if (!url) return '';

    const staticUrl = (process.env.NEXT_PUBLIC_STATIC_SERVER_URL || process.env.REACT_APP_STATIC_SERVER_URL) || '';

    if (url.startsWith('http')) return url;

    return (staticUrl + url);
};

export const calculateAge = (birthdate: string): number => {
    if (!birthdate) {
        return 0;
    }
    // Convert the birthdate to a Date object
    const birthDate = new Date(birthdate);
    const today = new Date();

    // Calculate the difference in years
    let age = today.getFullYear() - birthDate.getFullYear();

    // Adjust if the birthday has not occurred yet this year
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }

    return age;
}

const MIGRATION_ID_INCREMENT = 100000;

export const getMigrationId = (legacyId: number, context: TeamContext) => {
    if (isAcademySiteContext(context)) {
        if (legacyId < MIGRATION_ID_INCREMENT) {
            return legacyId + MIGRATION_ID_INCREMENT;
        }
    }

    return legacyId;
};

export const getLegacyId = (migrationId: number, context: TeamContext) => {
    if (isAcademySiteContext(context)) {
        if (migrationId > MIGRATION_ID_INCREMENT) {
            return migrationId - MIGRATION_ID_INCREMENT;
        }
    }

    return migrationId;
};

export const getPathParameter = (path: string, index?: number) => {
    const sections = path.split('/').filter((s) => s.length > 0);
    return sections[index ?? sections.length - 1];
}

export const updateHashParams = (params: Record<string, string>) => {
    const hashParams = new URLSearchParams(window.location.hash.slice(1));
    Object.keys(params).forEach(
        k => {
            hashParams.set(k, params[k]);
        }
    );
    window.location.hash = hashParams.toString();
}

export const getHashParam = (name: string) => {
    return getHashParams([name])?.[name];
}

export const getHashParams = (names: string[]) => {
    const params = new URLSearchParams(window.location.hash.slice(1));
    const res: Record<string, string> = {}
    names.forEach(n => {
        if (params.has(n)) {
            res[n] = params.get(n) || '';
        }
    });

    return res;
}

export const extractNumberFromUrl = (url: string): number | null => {
    // Regular expression to match a sequence of digits
    const regex = /\/(\d+)-/;
    const match = url.match(regex);

    // If a match is found, return the number, otherwise return null
    return match ? +match[1] : null;
}

export const getQticketsUrl = (url: string): string => {
    const eventId = extractNumberFromUrl(url);
    if (!eventId || eventId < 10000) {
        return url;
    }
    return `#qtickets:event_id=${eventId}&window_width=960&window_height=645&base_color=133d5e`;
}

export const getMediaTypeByUrl = (url: string): MediaType => {
    if (!url) {
        return MediaType.UNKNOWN;
    }

    if (url.includes('youtube')) {
        return MediaType.YOUTUBE;
    }

    if (url.includes('vk')) {
        return MediaType.VK;
    }

    if (url.includes('rutube')) {
        return MediaType.RT;
    }

    if (url.includes('.mp4')) {
        return MediaType.MP4;
    }

    if (url.includes('.jpg') || url.includes('.jpeg') || url.includes('.png')) {
        return MediaType.IMAGE;
    }

    return MediaType.UNKNOWN;
}

export const setBgImage = (url: string) => {
    const bgElement = document.querySelector('#innerContent') as HTMLElement | null;
    if (bgElement) {
        bgElement.setAttribute(
            'style',
            `background-image: url('${url}');background-attachment: fixed;background-position: center top; background-repeat: no-repeat; background-size: cover;`
        );
    }
}

export * from './text';
export * from './images';
export * from './clubTeams';